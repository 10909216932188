var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("topOperatingButton", {
        attrs: {
          disabled: _vm.disabledBtn,
          showAudit: false,
          isAuditBillBtn: false,
          isExamineBtn: true,
          id: "topOperatingButton",
        },
        on: {
          submitForm: function ($event) {
            return _vm.submitForm(false)
          },
          addBill: function ($event) {
            return _vm.submitForm(true)
          },
          auditBill: _vm.submitAudit,
          handleAdd: _vm.addScheme,
          getQuit: _vm.quitScheme,
        },
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.boxLoading,
              expression: "boxLoading",
            },
          ],
          staticClass: "special-scheme-detail",
          attrs: { "element-loading-text": "数据加载中" },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "80px",
              },
            },
            [
              _c(
                "cardTitleCom",
                { attrs: { cardTitle: "基本信息", icon: _vm.form.billStatus } },
                [
                  _c("template", { slot: "cardContent" }, [
                    _c("div", { staticClass: "basicInfo" }, [
                      _c(
                        "div",
                        { staticClass: "scheme x-f" },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "方案编号", prop: "billNo" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: "",
                                  size: "mini",
                                  placeholder: "自动生成",
                                },
                                model: {
                                  value: _vm.form.billNo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "billNo", $$v)
                                  },
                                  expression: "form.billNo",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "方案名称", prop: "promoName" } },
                            [
                              _c("el-input", {
                                attrs: { size: "mini", disabled: _vm.disabled },
                                model: {
                                  value: _vm.form.promoName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "promoName", $$v)
                                  },
                                  expression: "form.promoName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "scheme x-f" },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "促销日期", prop: "promoDate" } },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  size: "mini",
                                  type: "datetimerange",
                                  "range-separator": "至",
                                  "start-placeholder": "开始日期",
                                  "end-placeholder": "结束日期",
                                  "picker-options": _vm.forbiddenTime,
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  "default-time": ["00:00:00", "23:59:59"],
                                  disabled: _vm.disabled,
                                },
                                model: {
                                  value: _vm.form.promoDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "promoDate", $$v)
                                  },
                                  expression: "form.promoDate",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "适用渠道",
                                prop: "AllowOfflineShop",
                              },
                            },
                            [
                              _c(
                                "el-checkbox-group",
                                {
                                  model: {
                                    value: _vm.form.AllowOfflineShop,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "AllowOfflineShop",
                                        $$v
                                      )
                                    },
                                    expression: "form.AllowOfflineShop",
                                  },
                                },
                                [
                                  _c(
                                    "el-checkbox",
                                    {
                                      attrs: {
                                        label: "0",
                                        disabled: _vm.disabled,
                                      },
                                    },
                                    [_vm._v("线下门店 ")]
                                  ),
                                  _c(
                                    "el-checkbox",
                                    {
                                      attrs: {
                                        label: "1",
                                        disabled: _vm.disabled,
                                      },
                                    },
                                    [_vm._v("线上商城 ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ],
                2
              ),
              _c(
                "cardTitleCom",
                { attrs: { cardTitle: "活动规则" } },
                [
                  _c("template", { slot: "cardContent" }, [
                    _c(
                      "div",
                      { staticClass: "activity" },
                      [
                        _vm.form.promoType != 9
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "折上折",
                                  prop: "shopRangeType",
                                },
                              },
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    model: {
                                      value: _vm.form.isAllowTempReagio,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "isAllowTempReagio",
                                          $$v
                                        )
                                      },
                                      expression: "form.isAllowTempReagio",
                                    },
                                  },
                                  [_vm._v("支持临时改价/折扣折上折")]
                                ),
                                _c(
                                  "el-checkbox",
                                  {
                                    model: {
                                      value: _vm.form.isAllowVipReagio,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "isAllowVipReagio",
                                          $$v
                                        )
                                      },
                                      expression: "form.isAllowVipReagio",
                                    },
                                  },
                                  [_vm._v("支持会员折上折")]
                                ),
                                _c(
                                  "el-checkbox",
                                  {
                                    model: {
                                      value: _vm.form.isAllowCouponReagio,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "isAllowCouponReagio",
                                          $$v
                                        )
                                      },
                                      expression: "form.isAllowCouponReagio",
                                    },
                                  },
                                  [_vm._v("支持优惠券折上折")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "适用门店", prop: "shopRangeType" },
                          },
                          [
                            _c(
                              "el-radio-group",
                              {
                                attrs: { disabled: _vm.disabled },
                                model: {
                                  value: _vm.form.shopRangeType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "shopRangeType", $$v)
                                  },
                                  expression: "form.shopRangeType",
                                },
                              },
                              _vm._l(
                                _vm.dict.type.fill_shop_range_type,
                                function (dict) {
                                  return _c(
                                    "el-radio",
                                    {
                                      key: dict.value,
                                      attrs: {
                                        label: dict.value,
                                        value: dict.value,
                                      },
                                    },
                                    [_vm._v(_vm._s(dict.label) + " ")]
                                  )
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.form.shopRangeType === "1" ||
                        _vm.form.shopRangeType === "2"
                          ? _c(
                              "div",
                              { staticClass: "selectShop" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "shopBtn" },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "mini",
                                          disabled: _vm.disabled,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleEvent(
                                              "openShopDialog",
                                              {
                                                curTable: "openShopDialog",
                                              }
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("选择门店 ")]
                                    ),
                                  ],
                                  1
                                ),
                                _c("comTable", {
                                  staticClass: "marB10",
                                  attrs: {
                                    columnLabel: _vm.shopTableLable,
                                    tableData: _vm.form.shopItem,
                                    disabled: _vm.disabled,
                                  },
                                  on: {
                                    delTableRow: function ($event) {
                                      return _vm.delTableRow($event, "shop")
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            staticClass: "x-x",
                            staticStyle: {
                              height: "36px",
                              "margin-bottom": "10px",
                            },
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "适用对象",
                                  prop: "vipLevelRangeTypeArr",
                                },
                              },
                              [
                                _c(
                                  "el-checkbox-group",
                                  {
                                    attrs: { disabled: _vm.disabled },
                                    on: { change: _vm.handleCheckVip },
                                    model: {
                                      value: _vm.form.vipLevelRangeTypeArr,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "vipLevelRangeTypeArr",
                                          $$v
                                        )
                                      },
                                      expression: "form.vipLevelRangeTypeArr",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-checkbox",
                                      { attrs: { label: "0" } },
                                      [_vm._v("全部会员")]
                                    ),
                                    _c(
                                      "el-checkbox",
                                      { attrs: { label: "3" } },
                                      [_vm._v("非会员")]
                                    ),
                                    _c(
                                      "el-checkbox",
                                      { attrs: { label: "1" } },
                                      [_vm._v("全部会员+排除")]
                                    ),
                                    _c(
                                      "el-checkbox",
                                      { attrs: { label: "2" } },
                                      [_vm._v("指定会员")]
                                    ),
                                    _c(
                                      "el-checkbox",
                                      { attrs: { label: "4" } },
                                      [_vm._v("生日会员")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.showVipBD
                              ? _c(
                                  "el-select",
                                  {
                                    staticClass: "selectVipBD",
                                    attrs: {
                                      disabled: _vm.disabled,
                                      size: "mini",
                                    },
                                    model: {
                                      value: _vm.form.vipBirthdayType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "vipBirthdayType",
                                          $$v
                                        )
                                      },
                                      expression: "form.vipBirthdayType",
                                    },
                                  },
                                  _vm._l(
                                    _vm.dict.type.vip_birthday_type,
                                    function (dict) {
                                      return _c("el-option", {
                                        key: dict.value,
                                        attrs: {
                                          label: dict.label,
                                          value: dict.value,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm.vipTable
                          ? _c(
                              "div",
                              { staticClass: "selectVip" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "vipBtn" },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "mini",
                                          disabled: _vm.disabled,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleEvent(
                                              "openVipDialog",
                                              { curTable: "openVipDialog" }
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("选择会员")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "mini",
                                          disabled: _vm.disabled,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleEvent(
                                              "openVipLevelDialog",
                                              { curTable: "openVipLevelDialog" }
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("选择会员等级")]
                                    ),
                                  ],
                                  1
                                ),
                                _c("comTable", {
                                  staticClass: "marB10",
                                  attrs: {
                                    columnLabel: _vm.vipTableLable,
                                    tableData: _vm.form.vipLevelItem,
                                    disabled: _vm.disabled,
                                  },
                                  on: {
                                    delTableRow: function ($event) {
                                      return _vm.delTableRow($event, "vip")
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "tableMiddle",
                                        fn: function () {
                                          return [
                                            _c("el-table-column", {
                                              attrs: {
                                                label: "类型",
                                                align: "center",
                                                prop: "lineType",
                                                width: "123",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c("dict-tag", {
                                                          attrs: {
                                                            options:
                                                              _vm.dict.type
                                                                .member_type,
                                                            value:
                                                              scope.row
                                                                .lineType,
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                889154850
                                              ),
                                            }),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    1129299638
                                  ),
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "适用时间",
                              prop: "useTimeRangeType",
                            },
                          },
                          [
                            _c(
                              "el-radio-group",
                              {
                                attrs: { disabled: _vm.disabled },
                                model: {
                                  value: _vm.form.useTimeRangeType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "useTimeRangeType", $$v)
                                  },
                                  expression: "form.useTimeRangeType",
                                },
                              },
                              _vm._l(
                                _vm.dict.type.use_time_range_type,
                                function (dict) {
                                  return _c(
                                    "el-radio",
                                    {
                                      key: dict.value,
                                      attrs: {
                                        label: dict.value,
                                        value: dict.value,
                                      },
                                    },
                                    [_vm._v(_vm._s(dict.label) + " ")]
                                  )
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.form.useTimeRangeType === "2"
                          ? _c(
                              "div",
                              { staticClass: "timeTable" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "btn x-f marB10" },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "mini",
                                          disabled: _vm.disabled,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleEvent(
                                              "openTimeDateDialog",
                                              {
                                                curTable: "timeDateOptions",
                                              }
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("选择时间 ")]
                                    ),
                                  ],
                                  1
                                ),
                                _c("EditTable", {
                                  attrs: { options: _vm.timeDateOptions },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
              _vm.form.promoType === "5"
                ? _c(
                    "cardTitleCom",
                    { attrs: { cardTitle: "活动明细" } },
                    [
                      _c("template", { slot: "cardContent" }, [
                        _c(
                          "div",
                          { staticClass: "activityDetail" },
                          [
                            _c(
                              "div",
                              { staticClass: "top" },
                              [
                                _c(
                                  "el-radio",
                                  {
                                    attrs: {
                                      label: "1",
                                      disabled: _vm.disabled,
                                    },
                                    model: {
                                      value: _vm.form.buyFullQtyType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "buyFullQtyType",
                                          $$v
                                        )
                                      },
                                      expression: "form.buyFullQtyType",
                                    },
                                  },
                                  [_vm._v("同一商品买满件数 ")]
                                ),
                                _c(
                                  "el-radio",
                                  {
                                    attrs: {
                                      label: "2",
                                      disabled: _vm.disabled,
                                    },
                                    model: {
                                      value: _vm.form.buyFullQtyType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "buyFullQtyType",
                                          $$v
                                        )
                                      },
                                      expression: "form.buyFullQtyType",
                                    },
                                  },
                                  [_vm._v("适用范围内任选商品买满件数 ")]
                                ),
                              ],
                              1
                            ),
                            _c("activityTable", {
                              attrs: {
                                form: _vm.form,
                                tableFieldName: _vm.ADTableLabel,
                                disabled: _vm.disabled,
                              },
                              on: { handleTableRow: _vm.DetailTableRow },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  )
                : _vm._e(),
              _vm.form.promoType === "6"
                ? _c(
                    "cardTitleCom",
                    { attrs: { cardTitle: "活动明细" } },
                    [
                      _c("template", { slot: "cardContent" }, [
                        _c(
                          "div",
                          { staticClass: "activityDetail" },
                          [
                            _c(
                              "div",
                              { staticClass: "top" },
                              [
                                _c(
                                  "el-radio",
                                  {
                                    attrs: {
                                      label: "1",
                                      disabled: _vm.disabled,
                                    },
                                    model: {
                                      value: _vm.form.buyFullMoneyType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "buyFullMoneyType",
                                          $$v
                                        )
                                      },
                                      expression: "form.buyFullMoneyType",
                                    },
                                  },
                                  [_vm._v("同一商品买满金额 ")]
                                ),
                                _c(
                                  "el-radio",
                                  {
                                    attrs: {
                                      label: "2",
                                      disabled: _vm.disabled,
                                    },
                                    model: {
                                      value: _vm.form.buyFullMoneyType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "buyFullMoneyType",
                                          $$v
                                        )
                                      },
                                      expression: "form.buyFullMoneyType",
                                    },
                                  },
                                  [_vm._v("适用范围内任选商品买满金额 ")]
                                ),
                              ],
                              1
                            ),
                            _c("activityTable", {
                              attrs: {
                                form: _vm.form,
                                tableFieldName: _vm.MDTableLabel,
                                disabled: _vm.disabled,
                              },
                              on: { handleTableRow: _vm.DetailTableRow },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  )
                : _vm._e(),
              _vm.form.promoType === "7"
                ? _c(
                    "cardTitleCom",
                    { attrs: { cardTitle: "活动明细" } },
                    [
                      _c("template", { slot: "cardContent" }, [
                        _c(
                          "div",
                          { staticClass: "activityDetail" },
                          [
                            _c(
                              "div",
                              { staticClass: "top" },
                              [
                                _c(
                                  "el-radio",
                                  {
                                    attrs: {
                                      label: "1",
                                      disabled: _vm.disabled,
                                    },
                                    model: {
                                      value: _vm.form.buyFullMoneyType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "buyFullMoneyType",
                                          $$v
                                        )
                                      },
                                      expression: "form.buyFullMoneyType",
                                    },
                                  },
                                  [_vm._v("同一商品买满金额 ")]
                                ),
                                _c(
                                  "el-radio",
                                  {
                                    attrs: {
                                      label: "2",
                                      disabled: _vm.disabled,
                                    },
                                    model: {
                                      value: _vm.form.buyFullMoneyType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "buyFullMoneyType",
                                          $$v
                                        )
                                      },
                                      expression: "form.buyFullMoneyType",
                                    },
                                  },
                                  [_vm._v("适用范围内任选商品买满金额 ")]
                                ),
                              ],
                              1
                            ),
                            _c("activityTable", {
                              attrs: {
                                form: _vm.form,
                                tableFieldName: _vm.MFDTableLabel,
                                disabled: _vm.disabled,
                              },
                              on: { handleTableRow: _vm.DetailTableRow },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  )
                : _vm._e(),
              _vm.form.promoType === "8"
                ? _c(
                    "cardTitleCom",
                    { attrs: { cardTitle: "活动明细" } },
                    [
                      _c("template", { slot: "cardContent" }, [
                        _c(
                          "div",
                          { staticClass: "activityDetail" },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "买满方式",
                                  prop: "buyFullLargessType",
                                },
                              },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    attrs: { disabled: _vm.disabled },
                                    model: {
                                      value: _vm.form.buyFullLargessType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "buyFullLargessType",
                                          $$v
                                        )
                                      },
                                      expression: "form.buyFullLargessType",
                                    },
                                  },
                                  [
                                    _c("el-radio", { attrs: { label: "0" } }, [
                                      _vm._v("数量满赠"),
                                    ]),
                                    _c("el-radio", { attrs: { label: "1" } }, [
                                      _vm._v("金额满赠"),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.form.buyFullLargessType === "0"
                              ? _c("div", { staticClass: "amountFullGift" }, [
                                  _c(
                                    "div",
                                    { staticClass: "top" },
                                    [
                                      _c(
                                        "el-radio",
                                        {
                                          attrs: {
                                            label: "1",
                                            disabled: _vm.disabled,
                                          },
                                          model: {
                                            value: _vm.form.buyFullQtyType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "buyFullQtyType",
                                                $$v
                                              )
                                            },
                                            expression: "form.buyFullQtyType",
                                          },
                                        },
                                        [_vm._v("同一商品买满件数 ")]
                                      ),
                                      _c(
                                        "el-radio",
                                        {
                                          attrs: {
                                            label: "2",
                                            disabled: _vm.disabled,
                                          },
                                          model: {
                                            value: _vm.form.buyFullQtyType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "buyFullQtyType",
                                                $$v
                                              )
                                            },
                                            expression: "form.buyFullQtyType",
                                          },
                                        },
                                        [_vm._v("适用范围内任选商品买满件数 ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                            _vm.form.buyFullLargessType === "1"
                              ? _c("div", { staticClass: "moneyFullGift" }, [
                                  _c(
                                    "div",
                                    { staticClass: "top" },
                                    [
                                      _c(
                                        "el-radio",
                                        {
                                          attrs: {
                                            label: "1",
                                            disabled: _vm.disabled,
                                          },
                                          model: {
                                            value: _vm.form.buyFullMoneyType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "buyFullMoneyType",
                                                $$v
                                              )
                                            },
                                            expression: "form.buyFullMoneyType",
                                          },
                                        },
                                        [_vm._v("同一商品买满金额 ")]
                                      ),
                                      _c(
                                        "el-radio",
                                        {
                                          attrs: {
                                            label: "2",
                                            disabled: _vm.disabled,
                                          },
                                          model: {
                                            value: _vm.form.buyFullMoneyType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "buyFullMoneyType",
                                                $$v
                                              )
                                            },
                                            expression: "form.buyFullMoneyType",
                                          },
                                        },
                                        [_vm._v("适用范围内任选商品买满金额 ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                            _c(
                              "el-form-item",
                              {
                                staticClass: "x-f marT10",
                                attrs: {
                                  label:
                                    _vm.form.buyFullLargessType === "0"
                                      ? "买满数量"
                                      : "买满金额(元)",
                                  prop: "buyFullItem[" + 0 + "].buyFullValue",
                                  rules: _vm.rules["buyFullItem.buyFullValue"],
                                  "label-width": "105px",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    disabled: _vm.disabled,
                                    size: "mini",
                                  },
                                  model: {
                                    value: _vm.form.buyFullItem[0].buyFullValue,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form.buyFullItem[0],
                                        "buyFullValue",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.buyFullItem[0].buyFullValue",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  )
                : _vm._e(),
              _vm.form.promoType === "9"
                ? _c(
                    "cardTitleCom",
                    { attrs: { cardTitle: "活动明细" } },
                    [
                      _c("template", { slot: "cardContent" }, [
                        _c(
                          "div",
                          { staticClass: "activityDetail" },
                          [
                            _c(
                              "div",
                              { staticClass: "top" },
                              [
                                _c(
                                  "el-radio",
                                  {
                                    attrs: {
                                      label: "2",
                                      disabled: _vm.disabled,
                                    },
                                    model: {
                                      value: _vm.form.buyFullMoneyType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "buyFullMoneyType",
                                          $$v
                                        )
                                      },
                                      expression: "form.buyFullMoneyType",
                                    },
                                  },
                                  [_vm._v("适用范围内任选商品买满金额 ")]
                                ),
                              ],
                              1
                            ),
                            _c("activityTable", {
                              staticClass: "table",
                              attrs: {
                                form: _vm.form,
                                tableFieldName: _vm.RFTableLabel,
                                specialTableColumn: true,
                                disabled: _vm.disabled,
                              },
                              on: { handleTableRow: _vm.DetailTableRow },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  )
                : _vm._e(),
              _vm.form.promoType === "10"
                ? _c(
                    "cardTitleCom",
                    { attrs: { cardTitle: "活动明细" } },
                    [
                      _c("template", { slot: "cardContent" }, [
                        _c(
                          "div",
                          { staticClass: "activityDetail" },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "x-f",
                                attrs: {
                                  label: "组合名称",
                                  prop: "comboHead.comboHeadName",
                                  rules: _vm.rules["comboHead.comboHeadName"],
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    disabled: _vm.disabled,
                                    size: "mini",
                                  },
                                  model: {
                                    value: _vm.form.comboHead.comboHeadName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form.comboHead,
                                        "comboHeadName",
                                        $$v
                                      )
                                    },
                                    expression: "form.comboHead.comboHeadName",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "优惠方式",
                                  prop: "comboHead.discountType",
                                  rules: _vm.rules["comboHead.discountType"],
                                },
                              },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    staticClass: "discuountWayList",
                                    attrs: { disabled: _vm.disabled },
                                    model: {
                                      value: _vm.form.comboHead.discountType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form.comboHead,
                                          "discountType",
                                          $$v
                                        )
                                      },
                                      expression: "form.comboHead.discountType",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "x-bc item" },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: "1" } },
                                          [_vm._v("特价")]
                                        ),
                                        _vm.form.comboHead.discountType === "1"
                                          ? _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "价格(元)",
                                                  "label-width": "65px",
                                                  prop: "comboHead.discountValue",
                                                  rules:
                                                    _vm.rules[
                                                      "comboHead.discountValue"
                                                    ],
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    disabled: _vm.disabled,
                                                    size: "mini",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form.comboHead
                                                        .discountValue,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form.comboHead,
                                                        "discountValue",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.comboHead.discountValue",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "x-bc item" },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: "2" } },
                                          [_vm._v("优惠")]
                                        ),
                                        _vm.form.comboHead.discountType === "2"
                                          ? _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "金额(元)",
                                                  "label-width": "65px",
                                                  prop: "comboHead.discountValue",
                                                  rules:
                                                    _vm.rules[
                                                      "comboHead.discountValue"
                                                    ],
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    disabled: _vm.disabled,
                                                    size: "mini",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form.comboHead
                                                        .discountValue,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form.comboHead,
                                                        "discountValue",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.comboHead.discountValue",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "x-bc item" },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: "3" } },
                                          [_vm._v("折扣")]
                                        ),
                                        _vm.form.comboHead.discountType === "3"
                                          ? _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "折扣(%)",
                                                  "label-width": "65px",
                                                  prop: "comboHead.discountValue",
                                                  rules:
                                                    _vm.rules[
                                                      "comboHead.discountValue"
                                                    ],
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    disabled: _vm.disabled,
                                                    size: "mini",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form.comboHead
                                                        .discountValue,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form.comboHead,
                                                        "discountValue",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.comboHead.discountValue",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  )
                : _vm._e(),
              _vm.form.promoType === "0" ||
              _vm.form.promoType === "4" ||
              _vm.form.promoType === "5" ||
              _vm.form.promoType === "6" ||
              _vm.form.promoType === "7" ||
              _vm.form.promoType === "8" ||
              _vm.form.promoType === "9"
                ? _c(
                    "cardTitleCom",
                    { attrs: { cardTitle: "参与活动的商品" } },
                    [
                      _c("template", { slot: "cardContent" }, [
                        _c("div", { staticClass: "goods1" }, [
                          _c(
                            "div",
                            { staticClass: "top" },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "适用商品",
                                    prop: "goodsRangeType",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      attrs: { disabled: _vm.disabled },
                                      model: {
                                        value: _vm.form.goodsRangeType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "goodsRangeType",
                                            $$v
                                          )
                                        },
                                        expression: "form.goodsRangeType",
                                      },
                                    },
                                    _vm._l(
                                      _vm.dict.type.vip_goods_range_type,
                                      function (dict) {
                                        return _c(
                                          "el-radio",
                                          {
                                            key: dict.value,
                                            attrs: {
                                              label: dict.value,
                                              value: dict.value,
                                            },
                                          },
                                          [_vm._v(_vm._s(dict.label) + " ")]
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.form.goodsRangeType === "0" ||
                          _vm.form.goodsRangeType === "1"
                            ? _c(
                                "div",
                                { staticClass: "allGoods" },
                                [
                                  _vm.form.promoType === "0"
                                    ? _c(
                                        "el-form-item",
                                        {
                                          staticClass: "x-f marT15",
                                          attrs: {
                                            "label-width": "110px",
                                            label: "全部特价(元)",
                                            prop: "allSpecialPrice",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              disabled: _vm.disabled,
                                              size: "mini",
                                            },
                                            model: {
                                              value: _vm.form.allSpecialPrice,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "allSpecialPrice",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.allSpecialPrice",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.form.promoType === "4"
                                    ? _c(
                                        "el-form-item",
                                        {
                                          staticClass: "x-f marT15",
                                          attrs: {
                                            "label-width": "110px",
                                            label: "全部折扣(%)",
                                            prop: "allSpecialDiscount",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              disabled: _vm.disabled,
                                              size: "mini",
                                            },
                                            model: {
                                              value:
                                                _vm.form.allSpecialDiscount,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "allSpecialDiscount",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.allSpecialDiscount",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.form.goodsRangeType === "1" ||
                          _vm.form.goodsRangeType === "2"
                            ? _c(
                                "div",
                                { staticClass: "table" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "btn x-f" },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "mini",
                                            disabled: _vm.disabled,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleEvent(
                                                "openGoodsDialog",
                                                {
                                                  curTable: "openGoodsDialog",
                                                  radioSelect: false,
                                                  mutiSelect: true,
                                                }
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("选择商品")]
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "mini",
                                            disabled: _vm.disabled,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleEvent(
                                                "openCategoryDialog",
                                                {
                                                  curTable:
                                                    "openCategoryDialog",
                                                }
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("选择类别")]
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "mini",
                                            disabled: _vm.disabled,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleEvent(
                                                "openBrandDialog",
                                                { curTable: "openBrandDialog" }
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("选择品牌")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("Ttable", {
                                    attrs: {
                                      tableData: _vm.form.goodsItem,
                                      disabled: _vm.disabled,
                                    },
                                    on: {
                                      delTableRow: function ($event) {
                                        return _vm.delTableRow($event, "goods")
                                      },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        (_vm.form.promoType === "0" ||
                                          _vm.form.promoType === "4") &&
                                        _vm.form.goodsRangeType == 2
                                          ? {
                                              key: "discountPrice",
                                              fn: function () {
                                                return [
                                                  _vm.form.promoType === "0"
                                                    ? _c(
                                                        "el-table-column",
                                                        {
                                                          attrs: {
                                                            label: "特价(元)",
                                                            align: "center",
                                                            width: "180",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function (
                                                                  scope
                                                                ) {
                                                                  return [
                                                                    _c(
                                                                      "el-input",
                                                                      {
                                                                        attrs: {
                                                                          disabled:
                                                                            _vm.disabled,
                                                                          size: "mini",
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            scope
                                                                              .row
                                                                              .promoPrice,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                scope.row,
                                                                                "promoPrice",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "scope.row.promoPrice",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            false,
                                                            1727699601
                                                          ),
                                                        },
                                                        [
                                                          _c(
                                                            "template",
                                                            { slot: "header" },
                                                            [
                                                              _c(
                                                                "i",
                                                                {
                                                                  staticStyle: {
                                                                    color:
                                                                      "#ff4949",
                                                                  },
                                                                },
                                                                [_vm._v("*")]
                                                              ),
                                                              _vm._v(
                                                                " 特价(元) "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        2
                                                      )
                                                    : _vm._e(),
                                                  _vm.form.promoType === "4"
                                                    ? _c(
                                                        "el-table-column",
                                                        {
                                                          attrs: {
                                                            label: "折扣(%)",
                                                            align: "center",
                                                            width: "180",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function (
                                                                  scope
                                                                ) {
                                                                  return [
                                                                    _c(
                                                                      "el-input",
                                                                      {
                                                                        attrs: {
                                                                          size: "mini",
                                                                          disabled:
                                                                            _vm.disabled,
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            scope
                                                                              .row
                                                                              .discount,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                scope.row,
                                                                                "discount",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "scope.row.discount",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            false,
                                                            279811758
                                                          ),
                                                        },
                                                        [
                                                          _c(
                                                            "template",
                                                            { slot: "header" },
                                                            [
                                                              _c(
                                                                "i",
                                                                {
                                                                  staticStyle: {
                                                                    color:
                                                                      "#ff4949",
                                                                  },
                                                                },
                                                                [_vm._v("*")]
                                                              ),
                                                              _vm._v(
                                                                " 折扣(%) "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        2
                                                      )
                                                    : _vm._e(),
                                                ]
                                              },
                                              proxy: true,
                                            }
                                          : null,
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                      ]),
                    ],
                    2
                  )
                : _vm._e(),
              _vm.form.promoType === "1"
                ? _c(
                    "cardTitleCom",
                    { attrs: { cardTitle: "参与活动的商品" } },
                    [
                      _c("template", { slot: "cardContent" }, [
                        _c("div", { staticClass: "goods2" }, [
                          _c("div", { staticClass: "left" }, [
                            _c(
                              "div",
                              { staticClass: "topLeft" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      icon: "el-icon-plus",
                                      disabled: _vm.disabled,
                                    },
                                    on: { click: _vm.handleAddGoods },
                                  },
                                  [_vm._v("添加商品优惠 ")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "main" },
                              [
                                _c(
                                  "el-scrollbar",
                                  {
                                    staticStyle: {
                                      height: "100%",
                                      width: "100%",
                                    },
                                    attrs: {
                                      "wrap-style": "overflow-x:hidden;",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "list" },
                                      _vm._l(
                                        _vm.form.goodsItem,
                                        function (item, index) {
                                          return _c(
                                            "div",
                                            {
                                              key: item.lineId,
                                              staticClass: "item",
                                              class: {
                                                focusItem:
                                                  _vm.clickGoodsIndex === index,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.focusGoodsItem(
                                                    index
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "top" },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(item.lineNo || "")
                                                    ),
                                                  ]),
                                                  !_vm.disabled
                                                    ? _c("i", {
                                                        staticClass:
                                                          "el-icon-delete more",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.handleDelGoods(
                                                              index
                                                            )
                                                          },
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "name" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.lineName ||
                                                          "商品名称"
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              item.goodsBuyFullItem &&
                                              item.goodsName
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "discountList",
                                                    },
                                                    [
                                                      _c(
                                                        "el-scrollbar",
                                                        {
                                                          staticStyle: {
                                                            height: "100%",
                                                            width: "100%",
                                                          },
                                                          attrs: {
                                                            "wrap-style":
                                                              "overflow-x:hidden;",
                                                          },
                                                        },
                                                        _vm._l(
                                                          item.goodsBuyFullItem,
                                                          function (v, i) {
                                                            return _c(
                                                              "div",
                                                              {
                                                                key: i,
                                                                staticClass:
                                                                  "discountItem",
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      v.buyFullQty
                                                                        ? "满" +
                                                                            v.buyFullQty +
                                                                            "个,"
                                                                        : ""
                                                                    )
                                                                  ),
                                                                ]),
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      v.specialPrice
                                                                        ? v.specialPrice +
                                                                            "元"
                                                                        : ""
                                                                    )
                                                                  ),
                                                                ]),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "right" }, [
                            _c(
                              "div",
                              { staticClass: "topRight" },
                              [
                                _c(
                                  "i",
                                  {
                                    staticStyle: {
                                      color: "#ff4949",
                                      "margin-left": "20px",
                                    },
                                  },
                                  [_vm._v("*")]
                                ),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "商品编号" } },
                                  [
                                    _c("SelectRemote", {
                                      attrs: {
                                        size: "mini",
                                        disabled: _vm.disabled,
                                        option: _vm.optionGoods,
                                        filterable: true,
                                        placeholder: "选择商品",
                                      },
                                      model: {
                                        value:
                                          _vm.form.goodsItem[
                                            "" + _vm.clickGoodsIndex
                                          ].lineNo,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form.goodsItem[
                                              "" + _vm.clickGoodsIndex
                                            ],
                                            "lineNo",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.goodsItem[`${clickGoodsIndex}`].lineNo",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { "margin-left": "20px" },
                                    attrs: { label: "商品名称" },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "", size: "mini" },
                                      model: {
                                        value:
                                          _vm.form.goodsItem[
                                            "" + _vm.clickGoodsIndex
                                          ].lineName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form.goodsItem[
                                              "" + _vm.clickGoodsIndex
                                            ],
                                            "lineName",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.goodsItem[`${clickGoodsIndex}`].lineName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { "margin-left": "20px" },
                                    attrs: { label: "单位" },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          disabled: _vm.disabled,
                                          size: "mini",
                                          placeholder: "请选择单位",
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.changeUnitPrice(
                                              _vm.form.goodsItem[
                                                "" + _vm.clickGoodsIndex
                                              ],
                                              _vm.form.goodsItem[
                                                "" + _vm.clickGoodsIndex
                                              ].units,
                                              _vm.form.goodsItem[
                                                "" + _vm.clickGoodsIndex
                                              ].unitId
                                            )
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.form.goodsItem[
                                              "" + _vm.clickGoodsIndex
                                            ].unitId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.goodsItem[
                                                "" + _vm.clickGoodsIndex
                                              ],
                                              "unitId",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "form.goodsItem[`${clickGoodsIndex}`].unitId",
                                        },
                                      },
                                      _vm._l(
                                        _vm.form.goodsItem[
                                          "" + _vm.clickGoodsIndex
                                        ].units,
                                        function (item) {
                                          return _c("el-option", {
                                            key: item.unitId,
                                            attrs: {
                                              label: item.unitName,
                                              value: item.unitId,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { "margin-left": "20px" },
                                    attrs: { label: "零售价" },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { size: "mini", disabled: "" },
                                      model: {
                                        value:
                                          _vm.form.goodsItem[
                                            "" + _vm.clickGoodsIndex
                                          ].salePrice,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form.goodsItem[
                                              "" + _vm.clickGoodsIndex
                                            ],
                                            "salePrice",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.goodsItem[`${clickGoodsIndex}`].salePrice",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "main" },
                              [
                                _c(
                                  "el-scrollbar",
                                  {
                                    staticStyle: {
                                      height: "100%",
                                      width: "100%",
                                    },
                                    attrs: {
                                      "wrap-style": "overflow-x:hidden;",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "discountDetailList" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "leftLabel" },
                                          [_vm._v("优惠规则:")]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "rightCentent" },
                                          [
                                            _vm._l(
                                              _vm.form.goodsItem[
                                                "" + _vm.clickGoodsIndex
                                              ].goodsBuyFullItem,
                                              function (item, index) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: index,
                                                    staticClass: "item",
                                                  },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        attrs: {
                                                          label: "满",
                                                          prop:
                                                            "goodsItem[" +
                                                            _vm.clickGoodsIndex +
                                                            "].goodsBuyFullItembuyFullQty",
                                                          rules:
                                                            _vm.rules[
                                                              "details.unitQty"
                                                            ],
                                                        },
                                                      },
                                                      [
                                                        _c("el-input", {
                                                          attrs: {
                                                            size: "mini",
                                                            disabled:
                                                              _vm.disabled,
                                                          },
                                                          model: {
                                                            value:
                                                              item.buyFullQty,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                "buyFullQty",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.buyFullQty",
                                                          },
                                                        }),
                                                        _c("div", [
                                                          _vm._v("个,"),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        attrs: {
                                                          label: "特价",
                                                        },
                                                      },
                                                      [
                                                        _c("el-input", {
                                                          attrs: {
                                                            disabled:
                                                              _vm.disabled,
                                                            size: "mini",
                                                          },
                                                          model: {
                                                            value:
                                                              item.specialPrice,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                "specialPrice",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.specialPrice",
                                                          },
                                                        }),
                                                        _c("div", [
                                                          _vm._v("元"),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "del",
                                                        attrs: {
                                                          disabled:
                                                            _vm.disabled,
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleDelRule(
                                                              index
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v(" 删除 ")]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "ruleAdd" },
                                              [
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      size: "mini",
                                                      disabled: _vm.disabled,
                                                    },
                                                    on: {
                                                      click: _vm.handleAddRule,
                                                    },
                                                  },
                                                  [_vm._v("添加规则 ")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          2
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]),
                    ],
                    2
                  )
                : _vm._e(),
              _vm.form.promoType === "2"
                ? _c(
                    "cardTitleCom",
                    { attrs: { cardTitle: "参与活动的商品" } },
                    [
                      _c("template", { slot: "cardContent" }, [
                        _c("div", { staticClass: "goods3" }, [
                          _c(
                            "div",
                            { staticClass: "list" },
                            _vm._l(_vm.form.comboItem, function (item, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "item" },
                                [
                                  _c("div", { staticClass: "top x-bc" }, [
                                    _c(
                                      "div",
                                      { staticClass: "left x-f" },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            staticClass: "marR50",
                                            attrs: {
                                              label: "组合名称",
                                              "label-width": "80px",
                                              prop:
                                                "comboItem[" +
                                                index +
                                                "].comboName",
                                              rules:
                                                _vm.rules[
                                                  "comboItem.comboName"
                                                ],
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                placeholder: "请输入",
                                                size: "mini",
                                                disabled: _vm.disabled,
                                              },
                                              model: {
                                                value: item.comboName,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "comboName",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.comboName",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "组合价格(元)",
                                              "label-width": "105px",
                                              prop:
                                                "comboItem[" +
                                                index +
                                                "].comboPrice",
                                              rules:
                                                _vm.rules[
                                                  "comboItem.comboPrice"
                                                ],
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                placeholder: "请输入",
                                                size: "mini",
                                                disabled: _vm.disabled,
                                              },
                                              model: {
                                                value: item.comboPrice,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "comboPrice",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.comboPrice",
                                              },
                                            }),
                                            _c(
                                              "div",
                                              { staticClass: "total" },
                                              [_vm._v("零售价合计")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "rightBtn" },
                                      [
                                        index === 0
                                          ? _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "primary",
                                                  size: "mini",
                                                  icon: "el-icon-plus",
                                                  disabled: _vm.disabled,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleRow(
                                                      "push",
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("添加固定组合 ")]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "danger",
                                              icon: "el-icon-delete",
                                              size: "mini",
                                              disabled: _vm.disabled,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleRow(
                                                  "del",
                                                  index
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("删除该组合 ")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "table" },
                                    [
                                      _c(
                                        "el-table",
                                        {
                                          key: "lineId",
                                          ref: "dragTable",
                                          refInFor: true,
                                          staticClass: "table",
                                          attrs: {
                                            data: item.comboGoodsItem,
                                            border: "",
                                            "max-height": "300",
                                          },
                                        },
                                        [
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "序号",
                                              type: "index",
                                              width: "50",
                                              "class-name": "allowDrag",
                                              align: "center",
                                            },
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              align: "center",
                                              label: "操作",
                                              width: "120",
                                              "v-if": !_vm.disabled,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-circle-plus operatePush",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.tableRow(
                                                              item.comboGoodsItem,
                                                              "push",
                                                              scope.$index
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-remove operateDel",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.tableRow(
                                                              item.comboGoodsItem,
                                                              "del",
                                                              scope.$index
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                          _c(
                                            "el-table-column",
                                            {
                                              attrs: {
                                                label: "商品编码",
                                                width: "260",
                                                align: "center",
                                                prop: "lineNo",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              prop:
                                                                "comboItem[" +
                                                                index +
                                                                "].comboGoodsItem[" +
                                                                scope.$index +
                                                                "].lineNo",
                                                              rules:
                                                                _vm.rules[
                                                                  "comboItem.comboGoodsItem.lineNo"
                                                                ],
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                on: {
                                                                  mouseenter:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      _vm.signGroupIndex =
                                                                        index
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "SelectRemote",
                                                                  {
                                                                    attrs: {
                                                                      size: "mini",
                                                                      disabled:
                                                                        _vm.disabled,
                                                                      option:
                                                                        _vm.optionGoods,
                                                                      filterable: true,
                                                                      placeholder:
                                                                        "选择商品",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        scope
                                                                          .row
                                                                          .lineNo,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            scope.row,
                                                                            "lineNo",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "scope.row.lineNo",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "header" },
                                                [
                                                  _c(
                                                    "i",
                                                    {
                                                      staticStyle: {
                                                        color: "#ff4949",
                                                      },
                                                    },
                                                    [_vm._v("*")]
                                                  ),
                                                  _vm._v(" 商品编码 "),
                                                ]
                                              ),
                                            ],
                                            2
                                          ),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "商品名称",
                                              align: "center",
                                              prop: "lineName",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "单位",
                                              align: "center",
                                              width: "260",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c(
                                                        "el-select",
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              _vm.disabled,
                                                            size: "mini",
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              return _vm.changeUnitPrice(
                                                                scope.row,
                                                                scope.row.units,
                                                                scope.row.unitId
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              scope.row.unitId,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                scope.row,
                                                                "unitId",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "scope.row.unitId",
                                                          },
                                                        },
                                                        _vm._l(
                                                          scope.row.units,
                                                          function (item) {
                                                            return _c(
                                                              "el-option",
                                                              {
                                                                key: item.unitId,
                                                                attrs: {
                                                                  label:
                                                                    item.unitName,
                                                                  value:
                                                                    item.unitId,
                                                                },
                                                              }
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "售价(元)",
                                              align: "center",
                                              width: "200",
                                              prop: "salePrice",
                                            },
                                          }),
                                          _c(
                                            "el-table-column",
                                            {
                                              attrs: {
                                                label: "数量",
                                                align: "center",
                                                width: "200",
                                                prop: "limitQty",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              prop:
                                                                "comboItem[" +
                                                                index +
                                                                "].comboGoodsItem[" +
                                                                scope.$index +
                                                                "].limitQty",
                                                              rules:
                                                                _vm.rules[
                                                                  "comboItem.comboGoodsItem.limitQty"
                                                                ],
                                                            },
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                disabled:
                                                                  _vm.disabled,
                                                                size: "mini",
                                                              },
                                                              model: {
                                                                value:
                                                                  scope.row
                                                                    .limitQty,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      scope.row,
                                                                      "limitQty",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "scope.row.limitQty",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "header" },
                                                [
                                                  _c(
                                                    "i",
                                                    {
                                                      staticStyle: {
                                                        color: "#ff4949",
                                                      },
                                                    },
                                                    [_vm._v("*")]
                                                  ),
                                                  _vm._v(" 数量 "),
                                                ]
                                              ),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                        ]),
                      ]),
                    ],
                    2
                  )
                : _vm._e(),
              _vm.form.promoType === "3"
                ? _c(
                    "cardTitleCom",
                    { attrs: { cardTitle: "参与活动的商品" } },
                    [
                      _c("template", { slot: "cardContent" }, [
                        _c("div", { staticClass: "goods3" }, [
                          _c(
                            "div",
                            { staticClass: "list" },
                            _vm._l(_vm.form.comboItem, function (item, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "item" },
                                [
                                  _c("div", { staticClass: "top x-bc" }, [
                                    _c(
                                      "div",
                                      { staticClass: "left x-f" },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            staticClass: "marR50",
                                            attrs: {
                                              label: "组合名称",
                                              "label-width": "80px",
                                              prop:
                                                "comboItem[" +
                                                index +
                                                "].comboName",
                                              rules:
                                                _vm.rules[
                                                  "comboItem.comboName"
                                                ],
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                placeholder: "请输入",
                                                disabled: _vm.disabled,
                                                size: "mini",
                                              },
                                              model: {
                                                value: item.comboName,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "comboName",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.comboName",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-form-item",
                                          {
                                            staticClass: "marR50",
                                            attrs: {
                                              label: "组合价格(元)",
                                              "label-width": "105px",
                                              prop:
                                                "comboItem[" +
                                                index +
                                                "].comboPrice",
                                              rules:
                                                _vm.rules[
                                                  "comboItem.comboPrice"
                                                ],
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                placeholder: "请输入",
                                                size: "mini",
                                                disabled: _vm.disabled,
                                              },
                                              model: {
                                                value: item.comboPrice,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "comboPrice",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.comboPrice",
                                              },
                                            }),
                                            _c(
                                              "div",
                                              { staticClass: "total" },
                                              [_vm._v("零售价合计")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "任意",
                                              "label-width": "70px",
                                              prop:
                                                "comboItem[" +
                                                index +
                                                "].comboSelectQty",
                                              rules:
                                                _vm.rules[
                                                  "comboItem.comboSelectQty"
                                                ],
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                placeholder: "请输入",
                                                size: "mini",
                                                disabled: _vm.disabled,
                                              },
                                              model: {
                                                value: item.comboSelectQty,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "comboSelectQty",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "item.comboSelectQty",
                                              },
                                            }),
                                            _c(
                                              "div",
                                              { staticClass: "total" },
                                              [_vm._v("件")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "rightBtn" },
                                      [
                                        index === 0
                                          ? _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "primary",
                                                  size: "mini",
                                                  icon: "el-icon-plus",
                                                  disabled: _vm.disabled,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleRow(
                                                      "push",
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("添加动态组合 ")]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "danger",
                                              icon: "el-icon-delete",
                                              size: "mini",
                                              disabled: _vm.disabled,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleRow(
                                                  "del",
                                                  index
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("删除该组合 ")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "table" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "btn x-f marB10" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  _vm.signGroupIndex = index
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    size: "mini",
                                                    disabled: _vm.disabled,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleEvent(
                                                        "openGoodsDialog",
                                                        {
                                                          curTable:
                                                            "openGoodsDialog",
                                                          radioSelect: false,
                                                          mutiSelect: true,
                                                        }
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("选择商品")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "ml10",
                                              on: {
                                                click: function ($event) {
                                                  _vm.signGroupIndex = index
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    size: "mini",
                                                    disabled: _vm.disabled,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleEvent(
                                                        "openCategoryDialog",
                                                        {
                                                          curTable:
                                                            "openCategoryDialog",
                                                        }
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("选择类别")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "ml10",
                                              on: {
                                                click: function ($event) {
                                                  _vm.signGroupIndex = index
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    size: "mini",
                                                    disabled: _vm.disabled,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleEvent(
                                                        "openBrandDialog",
                                                        {
                                                          curTable:
                                                            "openBrandDialog",
                                                        }
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("选择品牌")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _c("Ttable", {
                                        attrs: {
                                          tableData: item.comboGoodsItem,
                                          disabled: _vm.disabled,
                                        },
                                        on: {
                                          delTableRow: function ($event) {
                                            return _vm.delTableRow(
                                              $event,
                                              "comboGoodsItem",
                                              index
                                            )
                                          },
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "discountPrice",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "el-table-column",
                                                    {
                                                      attrs: {
                                                        label: "最多可选数量",
                                                        align: "center",
                                                        width: "180",
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function (
                                                              scope
                                                            ) {
                                                              return [
                                                                _c(
                                                                  "el-form-item",
                                                                  {
                                                                    attrs: {
                                                                      prop:
                                                                        "comboItem[" +
                                                                        index +
                                                                        "].comboGoodsItem[" +
                                                                        scope.$index +
                                                                        "].limitQty",
                                                                      rules:
                                                                        _vm
                                                                          .rules[
                                                                          "comboItem.comboGoodsItem.limitQty"
                                                                        ],
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-input",
                                                                      {
                                                                        attrs: {
                                                                          disabled:
                                                                            _vm.disabled,
                                                                          oninput:
                                                                            "value=value.replace(/^([0-9-]\\d*\\.?\\d{0,2})?.*$/,'$1')",
                                                                          size: "mini",
                                                                        },
                                                                        on: {
                                                                          blur: function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.blurChange(
                                                                              scope
                                                                                .row
                                                                                .limitQty,
                                                                              scope.$index,
                                                                              index
                                                                            )
                                                                          },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            scope
                                                                              .row
                                                                              .limitQty,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                scope.row,
                                                                                "limitQty",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "scope.row.limitQty",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                    },
                                                    [
                                                      _c(
                                                        "template",
                                                        { slot: "header" },
                                                        [
                                                          _c(
                                                            "i",
                                                            {
                                                              staticStyle: {
                                                                color:
                                                                  "#ff4949",
                                                              },
                                                            },
                                                            [_vm._v("*")]
                                                          ),
                                                          _vm._v(
                                                            " 最多可选数量 "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                        ]),
                      ]),
                    ],
                    2
                  )
                : _vm._e(),
              _vm.form.promoType === "10"
                ? _c(
                    "cardTitleCom",
                    { attrs: { cardTitle: "参与活动的商品" } },
                    [
                      _c("template", { slot: "cardContent" }, [
                        _c("div", { staticClass: "goods3" }, [
                          _c(
                            "div",
                            { staticClass: "list" },
                            _vm._l(_vm.form.comboItem, function (item, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "item" },
                                [
                                  _c("div", { staticClass: "top x-bc" }, [
                                    _c(
                                      "div",
                                      { staticClass: "left x-f" },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            staticClass: "marR50",
                                            attrs: {
                                              label: "分类组合名称",
                                              "label-width": "110px",
                                              prop:
                                                "comboItem[" +
                                                index +
                                                "].comboName",
                                              rules:
                                                _vm.rules[
                                                  "comboItem.comboName"
                                                ],
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                placeholder: "请输入",
                                                size: "mini",
                                                disabled: _vm.disabled,
                                              },
                                              model: {
                                                value: item.comboName,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "comboName",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.comboName",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-radio-group",
                                          {
                                            attrs: { disabled: _vm.disabled },
                                            model: {
                                              value: item.comboSelectType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item,
                                                  "comboSelectType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "item.comboSelectType",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-radio",
                                              { attrs: { label: "0" } },
                                              [_vm._v("必选")]
                                            ),
                                            _c(
                                              "el-radio",
                                              { attrs: { label: "1" } },
                                              [_vm._v("可选")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-form-item",
                                          {
                                            staticClass: "num marL15",
                                            attrs: {
                                              label: "数量",
                                              "label-width": "60px",
                                              prop:
                                                "comboItem[" +
                                                index +
                                                "].comboSelectQty",
                                              rules:
                                                _vm.rules[
                                                  "comboItem.comboSelectQty"
                                                ],
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                disabled: _vm.disabled,
                                                size: "mini",
                                              },
                                              model: {
                                                value: item.comboSelectQty,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "comboSelectQty",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "item.comboSelectQty",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "rightBtn" },
                                      [
                                        index === 0
                                          ? _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "primary",
                                                  size: "mini",
                                                  icon: "el-icon-plus",
                                                  disabled: _vm.disabled,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleRow(
                                                      "push",
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("添加组合 ")]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "danger",
                                              icon: "el-icon-delete",
                                              size: "mini",
                                              disabled: _vm.disabled,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleRow(
                                                  "del",
                                                  index
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("删除该组合 ")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "table" },
                                    [
                                      _c(
                                        "el-table",
                                        {
                                          ref: "dragTable",
                                          refInFor: true,
                                          staticClass: "table",
                                          attrs: {
                                            data: item.comboGoodsItem,
                                            border: "",
                                            "max-height": "300",
                                          },
                                        },
                                        [
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "序号",
                                              type: "index",
                                              width: "80",
                                              align: "center",
                                            },
                                          }),
                                          !_vm.disabled
                                            ? _c("el-table-column", {
                                                attrs: {
                                                  align: "center",
                                                  label: "操作",
                                                  width: "120",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return [
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-circle-plus operatePush",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.tableRow(
                                                                  item.comboGoodsItem,
                                                                  "push",
                                                                  scope.$index
                                                                )
                                                              },
                                                            },
                                                          }),
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-remove operateDel",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.tableRow(
                                                                  item.comboGoodsItem,
                                                                  "del",
                                                                  scope.$index
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              })
                                            : _vm._e(),
                                          _c(
                                            "el-table-column",
                                            {
                                              attrs: {
                                                label: "编码",
                                                width: "260",
                                                align: "center",
                                                prop: "lineNo",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              prop:
                                                                "comboItem[" +
                                                                index +
                                                                "].comboGoodsItem[" +
                                                                scope.$index +
                                                                "].lineNo",
                                                              rules:
                                                                _vm.rules[
                                                                  "comboItem.comboGoodsItem.lineNo"
                                                                ],
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                on: {
                                                                  mouseenter:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      ;(_vm.signGroupIndex =
                                                                        index),
                                                                        (_vm.tabIndex =
                                                                          scope.$index)
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "SelectRemote",
                                                                  {
                                                                    attrs: {
                                                                      size: "mini",
                                                                      disabled:
                                                                        _vm.disabled,
                                                                      option:
                                                                        _vm.optionGoods,
                                                                      filterable: true,
                                                                      placeholder:
                                                                        "选择商品",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        scope
                                                                          .row
                                                                          .lineId,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            scope.row,
                                                                            "lineId",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "scope.row.lineId",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "header" },
                                                [
                                                  _c(
                                                    "i",
                                                    {
                                                      staticStyle: {
                                                        color: "#ff4949",
                                                      },
                                                    },
                                                    [_vm._v("*")]
                                                  ),
                                                  _vm._v(" 商品名称 "),
                                                ]
                                              ),
                                            ],
                                            2
                                          ),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "商品编码",
                                              align: "center",
                                              prop: "lineNo",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "单位",
                                              align: "center",
                                              width: "260",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c(
                                                        "el-select",
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              _vm.disabled,
                                                            size: "mini",
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              return _vm.changeUnitPrice(
                                                                scope.row,
                                                                scope.row.units,
                                                                scope.row.unitId
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              scope.row.unitId,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                scope.row,
                                                                "unitId",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "scope.row.unitId",
                                                          },
                                                        },
                                                        _vm._l(
                                                          scope.row.units,
                                                          function (item) {
                                                            return _c(
                                                              "el-option",
                                                              {
                                                                key: item.unitId,
                                                                attrs: {
                                                                  label:
                                                                    item.unitName,
                                                                  value:
                                                                    item.unitId,
                                                                },
                                                              }
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "售价(元)",
                                              align: "center",
                                              width: "200",
                                              prop: "salePrice",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                        ]),
                      ]),
                    ],
                    2
                  )
                : _vm._e(),
              _vm.form.promoType === "8"
                ? _c(
                    "cardTitleCom",
                    { attrs: { cardTitle: "赠送的商品/礼券" } },
                    [
                      _c("template", { slot: "cardContent" }, [
                        _c(
                          "div",
                          { staticClass: "goods4" },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "买赠方式",
                                  prop: "buyLargessType",
                                },
                              },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    attrs: { disabled: _vm.disabled },
                                    model: {
                                      value: _vm.form.buyLargessType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "buyLargessType",
                                          $$v
                                        )
                                      },
                                      expression: "form.buyLargessType",
                                    },
                                  },
                                  [
                                    _c("el-radio", { attrs: { label: "0" } }, [
                                      _vm._v("任选N件"),
                                    ]),
                                    _c("el-radio", { attrs: { label: "1" } }, [
                                      _vm._v("固定赠品"),
                                    ]),
                                    _c("el-radio", { attrs: { label: "2" } }, [
                                      _vm._v("赠送优惠券"),
                                    ]),
                                    _c("el-radio", { attrs: { label: "3" } }, [
                                      _vm._v("买一赠一"),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-tooltip",
                                  {
                                    staticStyle: { marginLeft: "10px" },
                                    attrs: { placement: "top" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "content" },
                                        slot: "content",
                                      },
                                      [
                                        _vm._v(
                                          " Tips: 买一赠一规则，活动明细设置将无效，系统将自动使用买一送一的规则处理 "
                                        ),
                                      ]
                                    ),
                                    _c("i", {
                                      staticClass: "el-icon-question",
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm.form.buyLargessType === "0"
                              ? _c(
                                  "div",
                                  { staticClass: "optional" },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticClass: "x-f",
                                        attrs: {
                                          "label-width": "110px",
                                          label: "赠送数量(件)",
                                          prop: "buyLargessQty",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            size: "mini",
                                            disabled: _vm.disabled,
                                          },
                                          model: {
                                            value: _vm.form.buyLargessQty,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "buyLargessQty",
                                                $$v
                                              )
                                            },
                                            expression: "form.buyLargessQty",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "table marT10" },
                                      [
                                        _c("div", { staticClass: "btn x-f" }, [
                                          _c(
                                            "div",
                                            {
                                              on: {
                                                mouseenter: function ($event) {
                                                  return _vm.openSelectGoods(
                                                    "",
                                                    "giveGoodsTable",
                                                    true
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    size: "mini",
                                                    disabled: _vm.disabled,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleEvent(
                                                        "openGoodsDialog",
                                                        {
                                                          curTable:
                                                            "openGoodsDialog",
                                                          radioSelect: false,
                                                          mutiSelect: true,
                                                        }
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("选择商品")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "ml10",
                                              on: {
                                                mouseenter: function ($event) {
                                                  return _vm.openSelectGoods(
                                                    "",
                                                    "giveGoodsTable",
                                                    true
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    size: "mini",
                                                    disabled: _vm.disabled,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleEvent(
                                                        "openCategoryDialog",
                                                        {
                                                          curTable:
                                                            "openCategoryDialog",
                                                        }
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("选择类别")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "ml10",
                                              on: {
                                                mouseenter: function ($event) {
                                                  return _vm.openSelectGoods(
                                                    "",
                                                    "giveGoodsTable",
                                                    true
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    size: "mini",
                                                    disabled: _vm.disabled,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleEvent(
                                                        "openBrandDialog",
                                                        {
                                                          curTable:
                                                            "openBrandDialog",
                                                        }
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("选择品牌")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]),
                                        _c("Ttable", {
                                          attrs: {
                                            tableData:
                                              _vm.form.optionalGoodsItem,
                                            disabled: _vm.disabled,
                                          },
                                          on: {
                                            delTableRow: function ($event) {
                                              return _vm.delTableRow(
                                                $event,
                                                "optionalGoodsItem"
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.form.buyLargessType === "1"
                              ? _c("div", { staticClass: "flxedGift" }, [
                                  _c(
                                    "div",
                                    { staticClass: "flxedGiftTable marT10" },
                                    [
                                      _c(
                                        "el-table",
                                        {
                                          ref: "goods-table",
                                          staticClass: "goods-table",
                                          attrs: {
                                            data: _vm.form.fixedGoodsItem,
                                            border: "",
                                            "max-height": "300",
                                            "row-key": "lineId",
                                          },
                                        },
                                        [
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "序号",
                                              type: "index",
                                              width: "50",
                                              align: "center",
                                            },
                                          }),
                                          !_vm.disabled
                                            ? _c("el-table-column", {
                                                attrs: {
                                                  align: "center",
                                                  label: "操作",
                                                  width: "120",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return [
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-circle-plus operatePush",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.tableRow(
                                                                  _vm.form
                                                                    .fixedGoodsItem,
                                                                  "push",
                                                                  scope.$index
                                                                )
                                                              },
                                                            },
                                                          }),
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-remove operateDel",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.tableRow(
                                                                  _vm.form
                                                                    .fixedGoodsItem,
                                                                  "del",
                                                                  scope.$index
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  3523621815
                                                ),
                                              })
                                            : _vm._e(),
                                          _c(
                                            "el-table-column",
                                            {
                                              key: "lineNo",
                                              attrs: {
                                                label: "商品编码",
                                                width: "220",
                                                align: "center",
                                                prop: "lineNo",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              "label-width":
                                                                "0px",
                                                              prop:
                                                                "fixedGoodsItem[" +
                                                                scope.$index +
                                                                "].lineNo",
                                                              rules:
                                                                _vm.rules[
                                                                  "fixedGoodsItem.lineNo"
                                                                ],
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                on: {
                                                                  mouseenter:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.openSelectGoods(
                                                                        "",
                                                                        "giveGoodsTable",
                                                                        true
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "SelectRemote",
                                                                  {
                                                                    attrs: {
                                                                      size: "mini",
                                                                      disabled:
                                                                        _vm.disabled,
                                                                      option:
                                                                        _vm.optionGoodsMutiSelect,
                                                                      filterable: true,
                                                                      placeholder:
                                                                        "选择商品",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        scope
                                                                          .row
                                                                          .lineId,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            scope.row,
                                                                            "lineId",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "scope.row.lineId",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                2319120578
                                              ),
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "header" },
                                                [
                                                  _c(
                                                    "i",
                                                    {
                                                      staticStyle: {
                                                        color: "#ff4949",
                                                      },
                                                    },
                                                    [_vm._v("*")]
                                                  ),
                                                  _vm._v(" 商品名称 "),
                                                ]
                                              ),
                                            ],
                                            2
                                          ),
                                          _c("el-table-column", {
                                            key: "lineNo",
                                            attrs: {
                                              label: "商品编码",
                                              align: "center",
                                              prop: "lineNo",
                                            },
                                          }),
                                          _c("el-table-column", {
                                            key: "goodsSpec",
                                            attrs: {
                                              label: "规格",
                                              align: "center",
                                              prop: "goodsSpec",
                                            },
                                          }),
                                          _c("el-table-column", {
                                            key: "unitId",
                                            attrs: {
                                              label: "单位",
                                              align: "center",
                                              prop: "unitId",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c(
                                                        "el-form-item",
                                                        [
                                                          _c(
                                                            "el-select",
                                                            {
                                                              attrs: {
                                                                disabled:
                                                                  _vm.disabled,
                                                                size: "mini",
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.changeUnitPrice(
                                                                      scope.row,
                                                                      scope.row
                                                                        .units,
                                                                      scope.row
                                                                        .unitId
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  scope.row
                                                                    .unitId,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      scope.row,
                                                                      "unitId",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "scope.row.unitId",
                                                              },
                                                            },
                                                            _vm._l(
                                                              scope.row.units,
                                                              function (item) {
                                                                return _c(
                                                                  "el-option",
                                                                  {
                                                                    key: item.unitId,
                                                                    attrs: {
                                                                      label:
                                                                        item.unitName,
                                                                      value:
                                                                        item.unitId,
                                                                    },
                                                                  }
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              1237201991
                                            ),
                                          }),
                                          _c(
                                            "el-table-column",
                                            {
                                              key: "limitQty",
                                              attrs: {
                                                label: "数量",
                                                align: "center",
                                                prop: "limitQty",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              prop:
                                                                "fixedGoodsItem[" +
                                                                scope.$index +
                                                                "].limitQty",
                                                              rules:
                                                                _vm.rules[
                                                                  "fixedGoodsItem.limitQty"
                                                                ],
                                                            },
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                disabled:
                                                                  _vm.disabled,
                                                                size: "mini",
                                                              },
                                                              model: {
                                                                value:
                                                                  scope.row
                                                                    .limitQty,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      scope.row,
                                                                      "limitQty",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "scope.row.limitQty",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                3310462785
                                              ),
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "header" },
                                                [
                                                  _c(
                                                    "i",
                                                    {
                                                      staticStyle: {
                                                        color: "#ff4949",
                                                      },
                                                    },
                                                    [_vm._v("*")]
                                                  ),
                                                  _vm._v(" 数量 "),
                                                ]
                                              ),
                                            ],
                                            2
                                          ),
                                          _c("el-table-column", {
                                            key: "promoPrice",
                                            attrs: {
                                              label: "加购金额",
                                              align: "center",
                                              prop: "promoPrice",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c("el-input", {
                                                        attrs: {
                                                          disabled:
                                                            _vm.disabled,
                                                          size: "mini",
                                                        },
                                                        model: {
                                                          value:
                                                            scope.row
                                                              .promoPrice,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              scope.row,
                                                              "promoPrice",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "scope.row.promoPrice",
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              1727699601
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                            _vm.form.buyLargessType === "2"
                              ? _c("div", { staticClass: "sendCoupon" }, [
                                  _c(
                                    "div",
                                    { staticClass: "CouponTable marT10" },
                                    [
                                      _c(
                                        "el-table",
                                        {
                                          ref: "couponTable",
                                          staticClass: "couponTable",
                                          attrs: {
                                            data: _vm.form.couponLargessItem,
                                            border: "",
                                            "max-height": "300",
                                            "row-key": "couponCaseId",
                                          },
                                        },
                                        [
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "序号",
                                              type: "index",
                                              width: "50",
                                              align: "center",
                                            },
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              align: "center",
                                              label: "操作",
                                              width: "120",
                                              "v-if": !_vm.disabled,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-circle-plus operatePush",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.tableRow(
                                                              _vm.form
                                                                .couponLargessItem,
                                                              "push",
                                                              scope.$index
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-remove operateDel",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.tableRow(
                                                              _vm.form
                                                                .couponLargessItem,
                                                              "del",
                                                              scope.$index
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              398602743
                                            ),
                                          }),
                                          _c(
                                            "el-table-column",
                                            {
                                              key: "couponCaseNo",
                                              attrs: {
                                                label: "优惠券方案编号",
                                                width: "220",
                                                align: "center",
                                                prop: "couponCaseNo",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              "label-width":
                                                                "0px",
                                                              prop:
                                                                "couponLargessItem[" +
                                                                scope.$index +
                                                                "].couponCaseNo",
                                                              rules:
                                                                _vm.rules[
                                                                  "couponLargessItem.couponCaseNo"
                                                                ],
                                                            },
                                                            nativeOn: {
                                                              keyup: function (
                                                                $event
                                                              ) {
                                                                if (
                                                                  !$event.type.indexOf(
                                                                    "key"
                                                                  ) &&
                                                                  _vm._k(
                                                                    $event.keyCode,
                                                                    "enter",
                                                                    13,
                                                                    $event.key,
                                                                    "Enter"
                                                                  )
                                                                ) {
                                                                  return null
                                                                }
                                                                return _vm.couponKeyupEnter(
                                                                  $event,
                                                                  scope.$index
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-input",
                                                              {
                                                                attrs: {
                                                                  size: "mini",
                                                                  disabled:
                                                                    _vm.disabled,
                                                                },
                                                                model: {
                                                                  value:
                                                                    scope.row
                                                                      .couponCaseNo,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        scope.row,
                                                                        "couponCaseNo",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "scope.row.couponCaseNo",
                                                                },
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "el-icon-more more",
                                                                  attrs: {
                                                                    slot: "suffix",
                                                                    disabled:
                                                                      _vm.disabled,
                                                                    size: "mini",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleEvent(
                                                                          "openCouponDialog"
                                                                        )
                                                                      },
                                                                  },
                                                                  slot: "suffix",
                                                                }),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                1882288671
                                              ),
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "header" },
                                                [
                                                  _c(
                                                    "i",
                                                    {
                                                      staticStyle: {
                                                        color: "#ff4949",
                                                      },
                                                    },
                                                    [_vm._v("*")]
                                                  ),
                                                  _vm._v(" 优惠券方案编号 "),
                                                ]
                                              ),
                                            ],
                                            2
                                          ),
                                          _c("el-table-column", {
                                            key: "couponCaseName",
                                            attrs: {
                                              label: "优惠券名称",
                                              align: "center",
                                              prop: "couponCaseName",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _c("el-table-column", {
                                            key: "couponCaseTypeName",
                                            attrs: {
                                              label: "优惠券类型",
                                              align: "center",
                                              prop: "couponCaseTypeName",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            scope.row
                                                              .couponCaseTypeName
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              3283840990
                                            ),
                                          }),
                                          _c("el-table-column", {
                                            key: "couponModeName",
                                            attrs: {
                                              label: "优惠券形式",
                                              align: "center",
                                              prop: "couponModeName",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            scope.row
                                                              .couponModeName
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              1237786225
                                            ),
                                          }),
                                          _c("el-table-column", {
                                            key: "couponDesc",
                                            attrs: {
                                              label: "优惠券内容",
                                              align: "center",
                                              prop: "couponDesc",
                                              "show-overflow-tooltip": true,
                                            },
                                          }),
                                          _c("el-table-column", {
                                            key: "expirationDate",
                                            attrs: {
                                              label: "有效期时间",
                                              align: "center",
                                              prop: "expirationDate",
                                              "show-overflow-tooltip": true,
                                            },
                                          }),
                                          _c("el-table-column", {
                                            key: "couponEffectModeName",
                                            attrs: {
                                              label: "生效方式",
                                              align: "center",
                                              prop: "couponEffectModeName",
                                              "show-overflow-tooltip": true,
                                            },
                                          }),
                                          _c(
                                            "el-table-column",
                                            {
                                              key: "limitQty",
                                              attrs: {
                                                label: "数量",
                                                align: "center",
                                                prop: "limitQty",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              prop:
                                                                "couponLargessItem[" +
                                                                scope.$index +
                                                                "].limitQty",
                                                              rules:
                                                                _vm.rules[
                                                                  "couponLargessItem.limitQty"
                                                                ],
                                                            },
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                disabled:
                                                                  _vm.disabled,
                                                                size: "mini",
                                                              },
                                                              model: {
                                                                value:
                                                                  scope.row
                                                                    .limitQty,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      scope.row,
                                                                      "limitQty",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "scope.row.limitQty",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                1236551521
                                              ),
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "header" },
                                                [
                                                  _c(
                                                    "i",
                                                    {
                                                      staticStyle: {
                                                        color: "#ff4949",
                                                      },
                                                    },
                                                    [_vm._v("*")]
                                                  ),
                                                  _vm._v(" 数量 "),
                                                ]
                                              ),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                            _vm.form.buyLargessType === "3"
                              ? _c(
                                  "div",
                                  { staticClass: "salesWith" },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "赠品规则",
                                          prop:
                                            "ruleLargessItem[" +
                                            0 +
                                            "].largessRule",
                                          rules:
                                            _vm.rules[
                                              "ruleLargessItem.largessRule"
                                            ],
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio-group",
                                          {
                                            attrs: { disabled: _vm.disabled },
                                            model: {
                                              value:
                                                _vm.form.ruleLargessItem[0]
                                                  .largessRule,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form.ruleLargessItem[0],
                                                  "largessRule",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.ruleLargessItem[0].largessRule",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "x-bc item" },
                                              [
                                                _c(
                                                  "el-radio",
                                                  { attrs: { label: "0" } },
                                                  [
                                                    _vm._v(
                                                      "买一赠一(赠送购买的商品)"
                                                    ),
                                                  ]
                                                ),
                                                _vm.form.ruleLargessItem[0]
                                                  .largessRule === "0"
                                                  ? _c(
                                                      "el-form-item",
                                                      {
                                                        attrs: {
                                                          prop:
                                                            "ruleLargessItem[" +
                                                            0 +
                                                            "].addMoney",
                                                          rules:
                                                            _vm.rules[
                                                              "ruleLargessItem.addMoney"
                                                            ],
                                                          label: "加购金额(元)",
                                                          "label-width": "90px",
                                                        },
                                                      },
                                                      [
                                                        _c("el-input", {
                                                          attrs: {
                                                            disabled:
                                                              _vm.disabled,
                                                            size: "mini",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.form
                                                                .ruleLargessItem[0]
                                                                .addMoney,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.form
                                                                  .ruleLargessItem[0],
                                                                "addMoney",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "form.ruleLargessItem[0].addMoney",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "x-bc item" },
                                              [
                                                _c(
                                                  "el-radio",
                                                  { attrs: { label: "1" } },
                                                  [
                                                    _vm._v(
                                                      "买一赠一(赠送价格低于购买商品的任意商品) "
                                                    ),
                                                  ]
                                                ),
                                                _vm.form.ruleLargessItem[0]
                                                  .largessRule === "1"
                                                  ? _c(
                                                      "el-form-item",
                                                      {
                                                        attrs: {
                                                          label: "加购金额(元)",
                                                          "label-width": "90px",
                                                          prop:
                                                            "ruleLargessItem[" +
                                                            0 +
                                                            "].addMoney",
                                                          rules:
                                                            _vm.rules[
                                                              "ruleLargessItem.addMoney"
                                                            ],
                                                        },
                                                      },
                                                      [
                                                        _c("el-input", {
                                                          attrs: {
                                                            disabled:
                                                              _vm.disabled,
                                                            size: "mini",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.form
                                                                .ruleLargessItem[0]
                                                                .addMoney,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.form
                                                                  .ruleLargessItem[0],
                                                                "addMoney",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "form.ruleLargessItem[0].addMoney",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "x-bc item" },
                                              [
                                                _c(
                                                  "el-radio",
                                                  { attrs: { label: "2" } },
                                                  [_vm._v("同品第二件折扣")]
                                                ),
                                                _vm.form.ruleLargessItem[0]
                                                  .largessRule === "2"
                                                  ? _c(
                                                      "el-form-item",
                                                      {
                                                        attrs: {
                                                          label: "折扣比例(%)",
                                                          "label-width": "90px",
                                                          prop:
                                                            "ruleLargessItem[" +
                                                            0 +
                                                            "].discountRate",
                                                          rules:
                                                            _vm.rules[
                                                              "ruleLargessItem.discountRate"
                                                            ],
                                                        },
                                                      },
                                                      [
                                                        _c("el-input", {
                                                          attrs: {
                                                            disabled:
                                                              _vm.disabled,
                                                            size: "mini",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.form
                                                                .ruleLargessItem[0]
                                                                .discountRate,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.form
                                                                  .ruleLargessItem[0],
                                                                "discountRate",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "form.ruleLargessItem[0].discountRate",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "x-bc item" },
                                              [
                                                _c(
                                                  "el-radio",
                                                  { attrs: { label: "3" } },
                                                  [_vm._v("同类第二件折扣")]
                                                ),
                                                _vm.form.ruleLargessItem[0]
                                                  .largessRule === "3"
                                                  ? _c(
                                                      "el-form-item",
                                                      {
                                                        attrs: {
                                                          label: "折扣比例(%)",
                                                          "label-width": "90px",
                                                          prop:
                                                            "ruleLargessItem[" +
                                                            0 +
                                                            "].discountRate",
                                                          rules:
                                                            _vm.rules[
                                                              "ruleLargessItem.discountRate"
                                                            ],
                                                        },
                                                      },
                                                      [
                                                        _c("el-input", {
                                                          attrs: {
                                                            disabled:
                                                              _vm.disabled,
                                                            size: "mini",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.form
                                                                .ruleLargessItem[0]
                                                                .discountRate,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.form
                                                                  .ruleLargessItem[0],
                                                                "discountRate",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "form.ruleLargessItem[0].discountRate",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "x-bc item" },
                                              [
                                                _c(
                                                  "el-radio",
                                                  { attrs: { label: "4" } },
                                                  [_vm._v("同品第二件加购")]
                                                ),
                                                _vm.form.ruleLargessItem[0]
                                                  .largessRule === "4"
                                                  ? _c(
                                                      "el-form-item",
                                                      {
                                                        attrs: {
                                                          label: "加购金额(元)",
                                                          "label-width": "90px",
                                                          prop:
                                                            "ruleLargessItem[" +
                                                            0 +
                                                            "].addMoney",
                                                          rules:
                                                            _vm.rules[
                                                              "ruleLargessItem.addMoney"
                                                            ],
                                                        },
                                                      },
                                                      [
                                                        _c("el-input", {
                                                          attrs: {
                                                            disabled:
                                                              _vm.disabled,
                                                            size: "mini",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.form
                                                                .ruleLargessItem[0]
                                                                .addMoney,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.form
                                                                  .ruleLargessItem[0],
                                                                "addMoney",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "form.ruleLargessItem[0].addMoney",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "x-bc item" },
                                              [
                                                _c(
                                                  "el-radio",
                                                  { attrs: { label: "5" } },
                                                  [_vm._v("同类第二件加购")]
                                                ),
                                                _vm.form.ruleLargessItem[0]
                                                  .largessRule === "5"
                                                  ? _c(
                                                      "el-form-item",
                                                      {
                                                        attrs: {
                                                          label: "加购金额(元)",
                                                          "label-width": "90px",
                                                          prop:
                                                            "ruleLargessItem[" +
                                                            0 +
                                                            "].addMoney",
                                                          rules:
                                                            _vm.rules[
                                                              "ruleLargessItem.addMoney"
                                                            ],
                                                        },
                                                      },
                                                      [
                                                        _c("el-input", {
                                                          attrs: {
                                                            disabled:
                                                              _vm.disabled,
                                                            size: "mini",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.form
                                                                .ruleLargessItem[0]
                                                                .addMoney,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.form
                                                                  .ruleLargessItem[0],
                                                                "addMoney",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "form.ruleLargessItem[0].addMoney",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  )
                : _vm._e(),
              _c(
                "cardTitleCom",
                { attrs: { cardTitle: "备注" } },
                [
                  _c("template", { slot: "cardContent" }, [
                    _c(
                      "div",
                      { staticClass: "remark" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "方案备注" } },
                          [
                            _c("el-input", {
                              staticClass: "billRemarkArea",
                              attrs: {
                                type: "textarea",
                                placeholder: "请输入方案备注备注",
                                maxlength: "200",
                                "show-word-limit": "",
                                rows: 3,
                                disabled: _vm.disabled,
                                size: "mini",
                              },
                              model: {
                                value: _vm.form.billRemark,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "billRemark", $$v)
                                },
                                expression: "form.billRemark",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c("selectShop", {
            attrs: { OpenShop: _vm.OpenShop },
            on: {
              "update:OpenShop": function ($event) {
                _vm.OpenShop = $event
              },
              "update:open-shop": function ($event) {
                _vm.OpenShop = $event
              },
              getShopData: function ($event) {
                return _vm.getPitchData($event, "shop")
              },
            },
          }),
          _c("selectVipRank", {
            attrs: { OpenVipRank: _vm.OpenVipRank },
            on: {
              "update:OpenVipRank": function ($event) {
                _vm.OpenVipRank = $event
              },
              "update:open-vip-rank": function ($event) {
                _vm.OpenVipRank = $event
              },
              getVipRank: function ($event) {
                return _vm.getPitchData($event, "vipRank")
              },
            },
          }),
          _c("selectVips", {
            attrs: { OpenVipCards: _vm.OpenVipCards },
            on: {
              "update:OpenVipCards": function ($event) {
                _vm.OpenVipCards = $event
              },
              "update:open-vip-cards": function ($event) {
                _vm.OpenVipCards = $event
              },
              getVipS: function ($event) {
                return _vm.getPitchData($event, "vipItem")
              },
            },
          }),
          _c("selectGoods", {
            attrs: {
              OpenGoods: _vm.OpenGoods,
              isRadio: _vm.isSelectGoodsRadio,
            },
            on: {
              "update:OpenGoods": function ($event) {
                _vm.OpenGoods = $event
              },
              "update:open-goods": function ($event) {
                _vm.OpenGoods = $event
              },
              getGoodsIds: function ($event) {
                return _vm.getPitchData($event, "goodsItem")
              },
              getGoodsItem: function ($event) {
                return _vm.getPitchData($event, "goodsItem")
              },
            },
          }),
          _c("selectCategory", {
            attrs: { OpenCategory: _vm.OpenCategory },
            on: {
              "update:OpenCategory": function ($event) {
                _vm.OpenCategory = $event
              },
              "update:open-category": function ($event) {
                _vm.OpenCategory = $event
              },
              getCategoryData: function ($event) {
                return _vm.getPitchData($event, "goodsCategory")
              },
            },
          }),
          _c("selectBrand", {
            attrs: { OpenBrand: _vm.OpenBrand },
            on: {
              "update:OpenBrand": function ($event) {
                _vm.OpenBrand = $event
              },
              "update:open-brand": function ($event) {
                _vm.OpenBrand = $event
              },
              brandList: function ($event) {
                return _vm.getPitchData($event, "goodsBrand")
              },
            },
          }),
          _c("selectDate", {
            attrs: { OpenDate: _vm.OpenDate, DateTimeList: _vm.DateTimeList },
            on: {
              "update:OpenDate": function ($event) {
                _vm.OpenDate = $event
              },
              "update:open-date": function ($event) {
                _vm.OpenDate = $event
              },
              getDateTimeList: function ($event) {
                return _vm.getPitchData($event, "time")
              },
            },
          }),
          _c("selectCoupon", {
            attrs: {
              OpenCoupon: _vm.OpenCoupon,
              isSuingEditorIsMultiple: true,
            },
            on: {
              "update:OpenCoupon": function ($event) {
                _vm.OpenCoupon = $event
              },
              "update:open-coupon": function ($event) {
                _vm.OpenCoupon = $event
              },
              getCoupons: function ($event) {
                return _vm.getPitchData($event, "coupon")
              },
            },
          }),
          _c("Dialog", {
            ref: "tablePage",
            attrs: { options: _vm.dialogOptions },
            on: {
              "update:options": function ($event) {
                _vm.dialogOptions = $event
              },
              handleEvent: _vm.handleEvent,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }