<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" />
    <Dialog ref="tablePage" :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import Dialog from '@/components/Dialog'
import { buyAnalyseListAPI ,buyAnalyseListAPISummary} from '@/api/marketing/promo/analyse' //促销分析
export default {
  name: "analyse",
  components: { TablePage, Dialog },
  dicts: ["bill_status", 'marketing_promo_type'],
  data() {
    return {
      dialogOptions: {
        curType: '', //当前类型(门店/商品/类别/品牌)
        title: '选择门店',
        width: 1250,
        show: false,
        type: 'TreeAndTable'
      },
      options: {
        mutiSelect: false, // 多选
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        //弹窗配置
        getListApi: buyAnalyseListAPI,
        getSummaryApi: buyAnalyseListAPISummary,
        exportOption: {
          fastExportUrl: '/api/system/marketing/base/promotion/analysis/listSummaryExport',
          exportName: '促销分析'
        },
        body: {},
        listNo: true, // 序号
        title: "单据信息",
        check: [], // 选中数据
        rowKey: "billId",
        search: [
          {
            label: "单据日期",
            type: "datetimerange",
            model: "",
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "day",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "filters",
            tip: "全部/商品编码/商品名称/门店编号/门店名称",
            isRestore: true,
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "promoCaseNos", label: "方案编号" },
              { filter: "promoCaseNames", label: "方案名称" },
            ],
          },
          this.$select({ key: 'listShop', option: { seniorSearch: true, option: { multiple: true } } }),
          this.$select({ key: "listGoods", option: { seniorSearch: true, option: { multiple: true } } }),
          this.$select({ key: "listCategory", option: { seniorSearch: true, option: { multiple: true } } }),
          this.$select({
            key: 'marketing_promo_type',
            option: {
              seniorSearch: true,
              option: {
                multiple: true,
              }
            }
          }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        columns: [
          {
            prop: "promoCaseNo",
            label: "活动方案编号",
            minWidth: 150,
          },
          {
            prop: "promoCaseName",
            label: "活动方案名称",
            minWidth: 120,
          },
          {
            prop: "shopNo",
            label: "门店编号",
            minWidth: 120,
          },
          {
            prop: "shopName",
            label: "门店名称",
            minWidth: 150,
          },
          {
            prop: "goodsNo",
            label: "商品编码",
            minWidth: 120,
          },
          {
            prop: "goodsName",
            label: "商品名称",
            minWidth: 120,
          },
          {
            prop: "barcode",
            label: "条码",
            minWidth: 120,
          },
          {
            prop: "goodsSpec",
            label: "规格",
            minWidth: 100,
          },
          {
            prop: "unitName",
            label: "单位",
            minWidth: 100,
          },
          {
            prop: "promoType",
            label: "方案类型",
            minWidth: 100,
            type: "dict",
            dict: "marketing_promo_type",
          },
          {
            prop: "unitOldPrice",
            label: "原价",
            minWidth: 100,
          },
          {
            prop: "unitPrice",
            label: "售价",
            minWidth: 100,
          },
          {
            prop: "unitQty",
            label: "销售数量",
            minWidth: 100,
          },
          {
            prop: "unitMoney",
            label: "销售金额",
            minWidth: 100,
          },
          {
            prop: "agioMoney",
            label: "优惠金额",
            minWidth: 100,
          },
          {
            prop: "billNo",
            label: "小票号",
            minWidth: 150,
          },
        ],
        summary: ['unitQty', 'unitMoney', 'agioMoney'],
        list: [],
      },
    };
  },
  methods: {
    seniorSearch() { },
    handleEvent(type, row) {
      console.log(type, row)
    }
  },
};
</script>
<style lang="scss" scoped></style>
