var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "special-scheme" },
    [
      _c("el-card", { staticClass: "box-card" }, [
        _c(
          "div",
          {
            staticClass: "clearfix",
            attrs: { slot: "header" },
            slot: "header",
          },
          [_c("span", [_vm._v("选择促销类型")])]
        ),
        _c(
          "div",
          { staticClass: "main" },
          [
            _c("el-scrollbar", [
              _c(
                "div",
                { staticClass: "special", attrs: { title: "【特价】" } },
                [
                  _c("div", { staticClass: "title" }, [_vm._v("【特价】")]),
                  _c(
                    "div",
                    { staticClass: "list" },
                    _vm._l(_vm.specialList.special, function (item) {
                      return _c(
                        "div",
                        {
                          key: item.id,
                          staticClass: "item orange",
                          on: {
                            click: function ($event) {
                              return _vm.openSchemeDetail(item.id, item.title)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "left" }, [
                            _c("div", { staticClass: "content" }, [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "font-size": "14px",
                                    color: "#333333",
                                  },
                                },
                                [_vm._v(_vm._s(item.content))]
                              ),
                              _c("div", { staticClass: "tag" }, [
                                _vm._v(_vm._s(item.tag)),
                              ]),
                            ]),
                          ]),
                          _c("div", { staticClass: "right" }, [
                            _c("div", { staticClass: "text" }, [
                              _vm._v(_vm._s(item.title)),
                            ]),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "discount", attrs: { title: "【折扣】" } },
                [
                  _c("div", { staticClass: "title" }, [_vm._v("【折扣】")]),
                  _c(
                    "div",
                    { staticClass: "list" },
                    _vm._l(_vm.specialList.discount, function (item) {
                      return _c(
                        "div",
                        {
                          key: item.id,
                          staticClass: "item blue",
                          on: {
                            click: function ($event) {
                              return _vm.openSchemeDetail(item.id, item.title)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "left" }, [
                            _c("div", { staticClass: "content" }, [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "font-size": "14px",
                                    color: "#333333",
                                  },
                                },
                                [_vm._v(_vm._s(item.content))]
                              ),
                              _c("div", { staticClass: "tag" }, [
                                _vm._v(_vm._s(item.tag)),
                              ]),
                            ]),
                          ]),
                          _c("div", { staticClass: "right" }, [
                            _c("div", { staticClass: "text" }, [
                              _vm._v(_vm._s(item.title)),
                            ]),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "presenter", attrs: { title: "【满赠/满减】" } },
                [
                  _c("div", { staticClass: "title" }, [
                    _vm._v("【满赠/满减】"),
                  ]),
                  _c(
                    "div",
                    { staticClass: "list" },
                    _vm._l(_vm.specialList.presenter, function (item) {
                      return _c(
                        "div",
                        {
                          key: item.id,
                          staticClass: "item pink",
                          on: {
                            click: function ($event) {
                              return _vm.openSchemeDetail(item.id, item.title)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "left" }, [
                            _c("div", { staticClass: "content" }, [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "font-size": "14px",
                                    color: "#333333",
                                  },
                                },
                                [_vm._v(_vm._s(item.content))]
                              ),
                              _c("div", { staticClass: "tag" }, [
                                _vm._v(_vm._s(item.tag)),
                              ]),
                            ]),
                          ]),
                          _c("div", { staticClass: "right" }, [
                            _c("div", { staticClass: "text" }, [
                              _vm._v(_vm._s(item.title)),
                            ]),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "presenter", attrs: { title: "【组合】" } },
                [
                  _c("div", { staticClass: "title" }, [_vm._v("【组合】")]),
                  _c(
                    "div",
                    { staticClass: "list" },
                    _vm._l(_vm.specialList.group, function (item) {
                      return _c(
                        "div",
                        {
                          key: item.id,
                          staticClass: "item green",
                          on: {
                            click: function ($event) {
                              return _vm.openSchemeDetail(item.id, item.title)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "left" }, [
                            _c("div", { staticClass: "content" }, [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "font-size": "14px",
                                    color: "#333333",
                                  },
                                },
                                [_vm._v(_vm._s(item.content))]
                              ),
                              _c("div", { staticClass: "tag" }, [
                                _vm._v(_vm._s(item.tag)),
                              ]),
                            ]),
                          ]),
                          _c("div", { staticClass: "right" }, [
                            _c("div", { staticClass: "text" }, [
                              _vm._v(_vm._s(item.title)),
                            ]),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              ),
            ]),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }